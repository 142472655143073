/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import {
  faExclamationTriangle,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import Module from './Module';
import DataPanel from '../../../../Components/DataPanel/DataPanel';
import LoadingBar from '../../../../Components/LoadingBar/LoadingBar';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import { TranslateTriggers } from '../../../../Components/Utilities/TriggerTexts';

export default function Management({ managementStatus, onPdfClick }) {
  const [translatedTriggers, setTranslatedTriggers] = useState([]);

  useEffect(() => {
    if (managementStatus !== null && Object.keys(managementStatus).length > 0) {
      setTranslatedTriggers(TranslateTriggers(managementStatus.triggers));
    }
  }, [managementStatus]);

  return (
    <Module>
      {managementStatus == null ? (
        <p className="module-empty-text">Finns ingen data för Förvaltning.</p>
      ) : managementStatus.managementReport == null ? (
        <LoadingBar active status="Hämtar data..." relative />
      ) : (
        <>
          <Module.Nav>
            <div />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <XpektorButton
                icon={faDownload}
                content="Spara som PDF"
                onClick={() =>
                  onPdfClick(() =>
                    document.getElementById('management-pdf-wrapper')
                  )
                }
              />
            </div>
          </Module.Nav>
          <div id="management-pdf-wrapper">
            <Module.KeyFigureSection>
              <Module.KeyFigure
                title="Soliditet"
                value={managementStatus.managementReport.solidityPercentage}
                valueType="percentage"
              />
              <Module.KeyFigure
                title="Skuldsättningsgrad"
                value={managementStatus.managementReport.debtPercentage}
                valueType="percentage"
              />
              <Module.KeyFigure
                title="Bruttomarginal"
                value={managementStatus.managementReport.grossProfitMargin}
                valueType="percentage"
              />
              <Module.KeyFigure
                title="Rörelsemarginal"
                value={managementStatus.managementReport.operatingMargin}
                valueType="percentage"
              />
              <Module.KeyFigure
                title="Kassalikviditet"
                value={managementStatus.managementReport.liquidity}
                valueType="percentage"
              />
            </Module.KeyFigureSection>
            <Module.DataSection>
              <Module.DataColumn>
                <DataPanel title="Indikationer på resultat">
                  <DataPanel.ValueEntry
                    label="Omsättningsvariation"
                    value={
                      managementStatus.managementReport
                        .revenueVariationPercentage
                    }
                    valueType="percentage"
                    info="Ökning/minskning i omsättning jämfört med förra månaden."
                  />
                  <DataPanel.ValueEntry
                    label="Månatlig genomsnittlig försäljning"
                    value={
                      managementStatus.managementReport.averageRevenue * -1
                    }
                    valueType="currency"
                    info="Genomsnittlig försäljning för företaget baserat på antal
              månader som förflutit."
                  />
                </DataPanel>
                <DataPanel title="Att ta hänsyn till" icon={faBell} background>
                  {translatedTriggers.map((data) => {
                    return (
                      <DataPanel.TriggerEntry
                        icon={faExclamationTriangle}
                        name={data.texts.name}
                        info={data.texts.info}
                        key={`${data.id} management`}
                        tooltipId={`${data.id} management`}
                      />
                    );
                  })}
                </DataPanel>
              </Module.DataColumn>
              <Module.DataColumn>
                <DataPanel title="Väsentliga kostnadsökningar / minskningar">
                  {managementStatus.triggers.includes(24) ? (
                    <DataPanel.ValueEntry
                      label="Övriga externa kostnader har ökat med mer än 30% från
                  föregående månad."
                    />
                  ) : managementStatus.triggers.includes(25) ? (
                    <DataPanel.ValueEntry
                      label="Övriga externa kostnader har minskat med mer än 30% från
                föregående månad."
                    />
                  ) : (
                    ''
                  )}
                  {managementStatus.triggers.includes(34) ? (
                    <DataPanel.ValueEntry
                      label="Kostnader för varor och material har ökat med mer än 30% från
                föregående månad."
                    />
                  ) : managementStatus.triggers.includes(35) ? (
                    <DataPanel.ValueEntry
                      label="Kostnader för varor och material har minskat med mer än 30% från
                föregående månad."
                    />
                  ) : (
                    ''
                  )}
                  {managementStatus.triggers.includes(36) ? (
                    <DataPanel.ValueEntry
                      label="Lön och personalkostnader har ökat med mer än 30% från
                föregående månad."
                    />
                  ) : managementStatus.triggers.includes(37) ? (
                    <DataPanel.ValueEntry
                      label="Lön och personalkostnader har minskat med mer än 30% från
                föregående månad."
                    />
                  ) : (
                    ''
                  )}
                  {managementStatus.triggers.includes(38) ? (
                    <DataPanel.ValueEntry
                      label="Nedskrivningar / avskrivningar har ökat med mer än 30% från
                föregående månad."
                    />
                  ) : managementStatus.triggers.includes(39) ? (
                    <DataPanel.ValueEntry
                      label="Nedskrivningar / avskrivningar har minskat med mer än 30% från
                föregående månad."
                    />
                  ) : (
                    ''
                  )}
                  {managementStatus.triggers.includes(40) ? (
                    <DataPanel.ValueEntry
                      label="Övriga finansiella kostnader har ökat med mer än 30% från
                föregående månad."
                    />
                  ) : managementStatus.triggers.includes(41) ? (
                    <DataPanel.ValueEntry
                      label="Övriga finansiella kostnader har minskat med mer än 30% från
                föregående månad."
                    />
                  ) : (
                    ''
                  )}
                </DataPanel>
                <DataPanel title="Indikationer på tillgångar">
                  {managementStatus.triggers.includes(28) ? (
                    <DataPanel.ValueEntry label="Lagret har ökat tre månader i rad." />
                  ) : managementStatus.triggers.includes(29) ? (
                    <DataPanel.ValueEntry label="Lagret är oförändrat tre månader i rad." />
                  ) : (
                    ''
                  )}
                  {managementStatus.triggers.includes(30) ? (
                    <DataPanel.ValueEntry label="Immateriella tillgångar ökar, se över nedskrivningsbehov." />
                  ) : (
                    ''
                  )}
                </DataPanel>
              </Module.DataColumn>
              <Module.DataColumn>
                <DataPanel title="Övriga rörelseåtgärder som bör åtgärdas">
                  {managementStatus.triggers.includes(26) ? (
                    <DataPanel.ValueEntry label="Företaget har ingen försäkring." />
                  ) : (
                    ''
                  )}
                  {managementStatus.triggers.includes(27) ? (
                    <DataPanel.ValueEntry label="Företaget redovisar advokatkostnader." />
                  ) : (
                    ''
                  )}
                  {managementStatus.triggers.includes(45) ? (
                    <DataPanel.ValueEntry label="Företaget redovisar förbjudna lån." />
                  ) : (
                    ''
                  )}
                </DataPanel>
                <DataPanel title="Indikationer på eget kapital / skulder">
                  {managementStatus.triggers.includes(31) ? (
                    <DataPanel.ValueEntry label="Eget kapital varierar under månaden." />
                  ) : (
                    ''
                  )}
                  {managementStatus.triggers.includes(32) ? (
                    <DataPanel.ValueEntry label="Långfristiga skulder har ökat under månaden." />
                  ) : (
                    ''
                  )}
                </DataPanel>
              </Module.DataColumn>
            </Module.DataSection>
          </div>
        </>
      )}
    </Module>
  );
}
