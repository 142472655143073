/* eslint-disable react/prop-types */
import './TransactionTable.scss';
import EyeIcon from '../../../../../../Images/Eye.png';

export default function TransactionTable({ children, inSums, outSums, error }) {
  return (
    <div className="transaction-table">
      <div className="transaction-table-items">
        {error ? <p className="load-fail">{error}</p> : children}
      </div>
      <div className="transaction-table-footer">
        <div className="transaction-sum-row">
          <p className="sum-title">Summa inbetalningar:</p>
          <div className="transaction-sum-column">
            {inSums.length !== 0 ? (
              inSums.map((currencySum) => {
                return (
                  <p key={`acc-insum-${currencySum.currency}`}>
                    {`${Number(currencySum.sum.toFixed(0))
                      .toLocaleString()
                      .replaceAll(',', ' ')} ${currencySum.currency}`}
                  </p>
                );
              })
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
        <div className="transaction-sum-row">
          <p className="sum-title">Summa utbetalningar:</p>
          <div className="transaction-sum-column">
            {outSums.length !== 0 ? (
              outSums.map((currencySum) => {
                return (
                  <p key={`acc-outsum-${currencySum.currency}`}>
                    {`${Number(Number(-currencySum.sum).toFixed(0))
                      .toLocaleString()
                      .replaceAll(',', ' ')} ${currencySum.currency}`}
                  </p>
                );
              })
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

TransactionTable.Item = function Item({ transaction, onClick }) {
  const amountSigned =
    transaction.creditDebitIndicator === 'DBIT'
      ? transaction.transactionAmount.amount * -1
      : transaction.transactionAmount.amount * 1; // to get rid of decimals

  return (
    <button className="transaction-item" onClick={onClick} type="button">
      <img
        className="transaction-item-logo"
        src={transaction.logo ?? EyeIcon}
        alt="Logo"
      />
      <div className="transaction-item-info">
        <p className="transaction-name">{transaction.name}</p>
        <p className="transaction-remittance">
          {transaction.remittanceInformation}
        </p>
      </div>
      <div className="transaction-item-money">
        <p>
          {Number(Number(amountSigned).toFixed(0))
            .toLocaleString()
            .replaceAll(',', ' ')}
        </p>
        <p>{transaction.transactionAmount.currency}</p>
      </div>
    </button>
  );
};
