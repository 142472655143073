import './CompanyInfo.scss';
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Moment from 'moment';
import generatePDF, { Margin } from 'react-to-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faCircleCheck as faCircleCheckSolid,
  faLinkSlash,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import ClientService from '../../../Services/ClientService';
import StatusService from '../../../Services/StatusService';
import UserService from '../../../Services/UserService';
import SimpleNotificationService from '../../../Services/SimpleNotificationService';
import LabeledInfo from '../../../Components/LabeledInfo/LabeledInfo';
import TabNav from '../../../Components/TabNav/TabNav';
import Equity from './Modules/Equity';
import Liquidity from './Modules/Liquidity';
import Management from './Modules/Management';
import Regulatory from './Modules/Regulatory';
import Accounts from './Modules/Accounts';
import Reports from './Modules/Reports';
import Score from './Modules/Score/Score';
import XpektorSlider from '../../../Components/XpektorSlider/XpektorSlider';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import ConnectBankModal from '../Modals/ConnectBankModal';
import ConnectAccountingModal from '../Modals/ConnectAccountingModal';
import ConnectSkvModal from '../Modals/ConnectSkvModal';
import ClientAuthLinkModal from '../Modals/ClientAuthLinkModal';

export default function CompanyInfo() {
  const { clientId } = useParams();
  const [client, setClient] = useState([]);
  const [clientLoaded, setClientLoaded] = useState(false); // Client can't be initizlied to null, so this is used as a nullcheck.
  const [bankGiro, setBankGiro] = useState(null);
  const [address, setAddress] = useState(null);

  const history = useHistory();
  const [authState, setAuthState] = useState({});

  const [equityStatus, setEquityStatus] = useState({});
  const [liquidityStatus, setLiquidityStatus] = useState({});
  const [managementStatus, setManagementStatus] = useState({});
  const [activeModule, setActiveModule] = useState('');

  const [showConnectBankModal, setShowConnectBankModal] = useState(false);
  const [showConnectAccountingModal, setShowConnectAccountingModal] =
    useState(false);
  const [showConnectSkvModal, setShowConnectSkvModal] = useState(false);
  const [showClientAuthLinkModal, setShowClientAuthLinkModal] = useState(false);

  const [lastUpdated, setLastUpdated] = useState('N/A'); // Status skapad
  const [lastChanged, setLastChanged] = useState(''); // Status ändrad

  const [statusColorClass, setStatusColorClass] = useState('');
  const [statusIcon, setStatusIcon] = useState(faExclamationTriangle);

  const formatDateTime = (dateTime) => {
    return Moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm');
  };

  const setStatusData = (companyStatus) => {
    setLastUpdated(formatDateTime(companyStatus.dateCreated));
    setLastChanged(formatDateTime(companyStatus.statusUpdatedDate));
    setStatusColorClass(
      companyStatus.clientStatus === 0
        ? 'ok'
        : companyStatus.clientStatus === 1
        ? 'warning'
        : 'error'
    );
    setStatusIcon(
      companyStatus.clientStatus === 0
        ? faCircleCheckSolid
        : faExclamationTriangle
    );
  };

  const clearStatusData = () => {
    setLastUpdated('N/A');
    setLastChanged('N/A');
    setStatusColorClass('');
    setStatusIcon(faExclamationTriangle);
  };

  const onSelectStatus = (companyStatus) => {
    if (companyStatus != null && Object.keys(companyStatus).length > 0) {
      setStatusData(companyStatus);
    } else {
      clearStatusData();
    }
  };

  const showEquity = () => {
    setActiveModule('equity');
    onSelectStatus(equityStatus);
    history.push(`/companyinfo/${clientId}/equity`);
  };

  const showLiquidity = () => {
    setActiveModule('liquidity');
    onSelectStatus(liquidityStatus);
    history.push(`/companyinfo/${clientId}/liquidity`);
  };

  const showManagement = () => {
    setActiveModule('management');
    onSelectStatus(managementStatus);
    history.push(`/companyinfo/${clientId}/management`);
  };

  const showRegulatory = () => {
    setActiveModule('regulatory');
    clearStatusData();
    history.push(`/companyinfo/${clientId}/regulatory`);
  };

  const showAccounts = () => {
    setActiveModule('accounts');
    clearStatusData();
    history.push(`/companyinfo/${clientId}/accounts`);
  };

  const showReports = () => {
    setActiveModule('reports');
    clearStatusData();
    history.push(`/companyinfo/${clientId}/reports`);
  };

  const showScore = () => {
    setActiveModule('score');
    clearStatusData();
    history.push(`/companyinfo/${clientId}/score`);
  };

  const isLocation = (path) => {
    return history.location.pathname.includes(path);
  };

  useEffect(() => {
    const getData = async () => {
      await ClientService.getClient(clientId)
        .then((response) => {
          setClient(response.data);
          setClientLoaded(true);
        })
        .catch(() => {});

      await ClientService.getClientSettings(clientId)
        .then((response) => {
          setAddress(
            `${response.data.address ?? ''}, ${
              response.data.postalCode ?? ''
            }, ${response.data.city ?? ''}`
          );
          setBankGiro(response.data.bankGiro);
        })
        .catch(() => {
          setAddress('N/A');
          setBankGiro('N/A');
        });

      await StatusService.getEquityLatest(clientId)
        .then((response) => {
          setEquityStatus(response.data);
          if (isLocation('equity')) {
            onSelectStatus(response.data);
          }
        })
        .catch(() => {
          setEquityStatus(null);
          if (isLocation('equity')) {
            clearStatusData();
          }
        });

      await StatusService.getLiquidityLatest(clientId)
        .then((response) => {
          setLiquidityStatus(response.data);
          if (isLocation('liquidity')) {
            onSelectStatus(response.data);
          }
        })
        .catch(() => {
          setLiquidityStatus(null);
          if (isLocation('liquidity')) {
            clearStatusData();
          }
        });

      await StatusService.getManagementLatest(clientId)
        .then((response) => {
          setManagementStatus(response.data);
          if (isLocation('management')) {
            onSelectStatus(response.data);
          }
        })
        .catch(() => {
          setManagementStatus(null);
          if (isLocation('management')) {
            clearStatusData();
          }
        });

      const user = UserService.getCurrentUser();
      await SimpleNotificationService.SetViewed(clientId, user.id);
      setAuthState((state) => ({ ...state, user }));
    };

    getData();
    if (isLocation('equity')) {
      setActiveModule('equity');
    } else if (isLocation('liquidity')) {
      setActiveModule('liquidity');
    } else if (isLocation('management')) {
      setActiveModule('management');
    } else if (isLocation('regulatory')) {
      setActiveModule('regulatory');
    } else if (isLocation('accounts')) {
      setActiveModule('accounts');
    } else if (isLocation('reports')) {
      setActiveModule('reports');
    } else if (isLocation('score')) {
      setActiveModule('score');
    }
  }, [clientId]);

  function CreatePdf(pdfContent) {
    return generatePDF(pdfContent, {
      filename: `${
        activeModule === 'equity'
          ? 'Eget_kapital'
          : activeModule === 'liquidity'
          ? 'Likviditet'
          : activeModule === 'management'
          ? 'Förvaltning'
          : activeModule === 'regulatory'
          ? 'Regulatoriskt'
          : activeModule === 'score'
          ? 'Score'
          : ''
      }-${Moment.utc().local().format('YYYY-MM-DD')}-${client.name}.pdf`,
      page: {
        margin: Margin.SMALL,
      },
    });
  }

  const renderModules = () => {
    return (
      <XpektorSlider
        activeSlideIndex={
          activeModule === 'score'
            ? 0
            : activeModule === 'equity'
            ? 1
            : activeModule === 'liquidity'
            ? 2
            : activeModule === 'management'
            ? 3
            : activeModule === 'regulatory'
            ? 4
            : activeModule === 'accounts'
            ? 5
            : activeModule === 'reports'
            ? 6
            : ''
        }
      >
        <XpektorSlider.Slide hidden={activeModule !== 'score'}>
          <Score
            client={client}
            onPdfClick={(pdfContent) => CreatePdf(pdfContent)}
          />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'equity'}>
          <Equity
            equityStatus={equityStatus}
            onPdfClick={(pdfContent) => CreatePdf(pdfContent)}
          />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'liquidity'}>
          <Liquidity
            liquidityStatus={liquidityStatus}
            onPdfClick={(pdfContent) => CreatePdf(pdfContent)}
          />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'management'}>
          <Management
            managementStatus={managementStatus}
            onPdfClick={(pdfContent) => CreatePdf(pdfContent)}
          />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'regulatory'}>
          <Regulatory
            client={client}
            skvConnected={client.skvConnected}
            onPdfClick={(pdfContent) => CreatePdf(pdfContent)}
          />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'accounts'}>
          <Accounts client={client} />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'reports'}>
          <Reports client={client} />
        </XpektorSlider.Slide>
      </XpektorSlider>
    );
  };

  return (
    <div className="company-info-wrapper">
      <div className="company-info-head">
        <div className="company-info-details">
          <div className="company-info-title-row">
            <p className="company-title">{client.name}</p>
          </div>
          <div className="company-info-sub-title-row">
            <LabeledInfo
              label="Org. nummer:"
              info={client.organizationNumber}
              animatedY
              animDelay={0.25}
              divider
            />
            {address != null && (
              <LabeledInfo
                label="Adress:"
                info={address}
                animatedY
                animDelay={0.5}
                divider
              />
            )}
            {bankGiro != null && (
              <LabeledInfo
                label="Bankgiro:"
                info={bankGiro}
                animatedY
                animDelay={0.75}
              />
            )}
          </div>
        </div>
        <div className="action-button-container">
          <div className="action-button-row">
            {clientLoaded === true && (
              <>
                <XpektorButton
                  content="Bokföring"
                  icon={client.externalConnectionActive ? faLink : faLinkSlash}
                  styleClass={
                    client.externalConnectionActive ? 'connected' : ''
                  }
                  onClick={() => {
                    setShowConnectAccountingModal(true);
                  }}
                  disabled={
                    client.externalConnectionActive || authState.user?.role < 1
                  }
                />
                <XpektorButton
                  content="Bank"
                  icon={client.bankConnectionActive ? faLink : faLinkSlash}
                  styleClass={
                    client.bankConnectionActive
                      ? 'connected'
                      : client.hadBankConnection
                      ? 'expired'
                      : ''
                  }
                  onClick={() => {
                    setShowConnectBankModal(true);
                  }}
                  disabled={
                    authState.user?.role < 1 && !client.bankConnectionActive
                  }
                />
                {!client.unconnected && (
                  <XpektorButton
                    content="Skatteverket"
                    icon={client.skvConnected ? faLink : faLinkSlash}
                    styleClass={client.skvConnected ? 'connected' : ''}
                    onClick={() => {
                      setShowConnectSkvModal(true);
                    }}
                    disabled={client.skvConnected || authState.user?.role < 1}
                  />
                )}
              </>
            )}
          </div>
          <div className="sub-action-row">
            {authState.user?.role > 0 && (
              <XpektorButton
                content="Extern länk"
                onClick={() => setShowClientAuthLinkModal(true)}
              />
            )}
          </div>
        </div>
      </div>
      <div className="company-info-nav-container">
        <TabNav>
          <TabNav.Tab
            title="Score (BETA)"
            active={activeModule === 'score'}
            onClick={showScore}
          />
          <TabNav.Tab
            title="Eget kapital"
            active={activeModule === 'equity'}
            onClick={showEquity}
          />
          <TabNav.Tab
            title="Likviditet"
            active={activeModule === 'liquidity'}
            onClick={showLiquidity}
          />
          <TabNav.Tab
            title="Förvaltning"
            active={activeModule === 'management'}
            onClick={showManagement}
          />
          <TabNav.Tab
            title="Regulatoriskt"
            active={activeModule === 'regulatory'}
            onClick={showRegulatory}
          />
          <TabNav.Tab
            title="Kontoanalys"
            active={activeModule === 'accounts'}
            onClick={showAccounts}
          />
          <TabNav.Tab
            title="Rapporter"
            active={activeModule === 'reports'}
            onClick={showReports}
          />
        </TabNav>
      </div>
      <div className="sub-nav-container">
        {activeModule === 'equity' ||
        activeModule === 'liquidity' ||
        activeModule === 'management' ? (
          <div className="status">
            {clientLoaded === true && !client.externalConnectionActive ? (
              <>
                <FontAwesomeIcon
                  className="status-icon-broken error"
                  icon={faLinkSlash}
                />
                <p className="status-text error">
                  Bokföring ej kopplat. Kan inte hämta data.
                </p>
              </>
            ) : (
              Object.keys(lastChanged).length !== 0 && (
                <>
                  <FontAwesomeIcon
                    className={`status-icon ${statusColorClass}`}
                    icon={statusIcon}
                  />
                  <div className="time-mask">
                    <LabeledInfo
                      label="Status ändrad:"
                      info={lastChanged}
                      animatedX
                    />
                  </div>
                </>
              )
            )}
          </div>
        ) : activeModule === 'accounts' && client.hadBankConnection ? (
          <div className="status">
            <FontAwesomeIcon
              className="status-icon-broken error"
              icon={faLinkSlash}
            />
            <p className="status-text error">
              Bankkopplingen bruten. Datan uppdateras inte.
            </p>
          </div>
        ) : (
          ''
        )}
        {lastUpdated !== 'N/A' && (
          <LabeledInfo
            label="Värden uppdaterade: "
            info={lastUpdated}
            animatedY
            animDelay={0.25}
          />
        )}
      </div>
      {renderModules()}
      {showConnectBankModal && (
        <ConnectBankModal
          handleClose={() => setShowConnectBankModal(false)}
          client={client}
        />
      )}
      {showConnectAccountingModal && (
        <ConnectAccountingModal
          handleClose={() => setShowConnectAccountingModal(false)}
          action="updateClient"
          client={client}
          authLevel={authState.user?.role}
        />
      )}
      {showConnectSkvModal && (
        <ConnectSkvModal
          clientId={clientId}
          handleClose={() => {
            setShowConnectSkvModal(false);
          }}
        />
      )}
      {showClientAuthLinkModal && (
        <ClientAuthLinkModal
          client={client}
          handleClose={() => setShowClientAuthLinkModal(false)}
        />
      )}
    </div>
  );
}
