import api from './ApiMiddleware';

const getClients = (customerId) => {
  return api.get(`/api/v1/clients?customerId=${customerId}`);
};

const getUsers = (customerId) => {
  return api.get(`/api/v1/user/users?customerId=${customerId}`);
};

const getCustomer = (customerId) => {
  return api.get(`/api/v1/customer/${customerId}`);
};

const updateCustomer = (customer) => {
  return api.put(`/api/v1/customer/${customer.id}`, {
    name: customer.name,
    mainCustomerId: customer.mainCustomerId,
    organizationNumber: customer.organizationNumber,
    contactPerson: customer.contactPerson,
    phoneNumber: customer.phoneNumber,
    billingEmail: customer.billingEmail,
  });
};

const getOnboardingInfo = () => {
  return api.get(`/api/v1/customer/onboarding`);
};

const CustomerService = {
  getClients,
  getUsers,
  getCustomer,
  updateCustomer,
  getOnboardingInfo,
};

export default CustomerService;
