/* eslint-disable react/prop-types */
import HoverableText from '../../../../Components/HoverableText/HoverableText';
import './Module.scss';

export default function Module({ children }) {
  return <div className="module-wrapper">{children}</div>;
}

Module.KeyFigureSection = function KeyFigureSection({ children }) {
  return <div className="key-figures-section">{children}</div>;
};

Module.KeyFigureSectionAccounts = function KeyFigureSectionAccounts({
  children,
}) {
  return <div className="key-figures-section accounts-module">{children}</div>;
};

Module.KeyFigure = function KeyFigure({
  title,
  value,
  valueType,
  currency,
  subTitle,
  info,
}) {
  let formattedValue = '';
  let formattedSign = '';

  if (valueType === 'currency') {
    formattedValue = Number(Number(value).toFixed(0))
      .toLocaleString()
      .replaceAll(',', ' ');
    formattedSign = currency ?? 'kr';
  } else if (valueType === 'percentage') {
    formattedValue = Number(value)
      .toFixed(0)
      .toLocaleString()
      .replaceAll(',', ' ');
    formattedSign = '%';
  } else {
    formattedValue = value;
    formattedSign = valueType;
  }

  return (
    <div className="key-figure-container">
      <div className="key-figure-content">
        <HoverableText styleClass="key-figure-title" text={title} info={info} />
        <div className="key-figure-value">
          <p className="value">{formattedValue}</p>
          <p className="value-sign">{formattedSign}</p>
        </div>
        <p className="key-figure-subtitle">{subTitle}</p>
      </div>
    </div>
  );
};

Module.DataSection = function DataSection({ children }) {
  return <div className="data-section">{children}</div>;
};

Module.DataColumn = function DataColumn({ children }) {
  return <div className="data-column">{children}</div>;
};

Module.Nav = function Nav({ children }) {
  return <div className="nav-bar">{children}</div>;
};

Module.TransactionContent = function TransactionContent({ children }) {
  return <div className="transaction-content">{children}</div>;
};

Module.ReportSection = function ReportSection({ vertical, children }) {
  return (
    <div className={vertical ? 'reports-section-vertical' : 'reports-section'}>
      {children}
    </div>
  );
};
