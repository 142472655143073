/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import './ConnectBankModal.scss';
import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Moment from 'moment';
import BankService from '../../../Services/BankService';
import Searchbar from '../../../Components/Searchbar/Searchbar';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import LoadingBar from '../../../Components/LoadingBar/LoadingBar';

function ConnectBankModal({ handleClose, client }) {
  const [banks, setBanks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [session, setSession] = useState(null);
  const [resettingBank, setResettingBank] = useState(false);

  useEffect(() => {
    const getSession = async () => {
      await BankService.getClientBankSession(client.id)
        .then((response) => {
          setSession(response.data);
        })
        .catch(() => {});
    };
    const getBanks = async () => {
      await BankService.getBanks('SE')
        .then((response) => {
          setBanks(response.data);
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert('Något gick fel försök igen om en stund');
          }
          if (error.response.status === 404) {
            alert('Kunde inte hämta några banker');
          }
        });
    };

    if (
      client.bankConnectionActive === true ||
      client.hadBankConnection === true
    ) {
      getSession();
    } else {
      getBanks();
    }
  }, [client]);

  const redirectToBank = async (clientId, bankName, bankCountry, psuType) => {
    await BankService.getAuthLinkForEnableBanking(
      clientId,
      bankName,
      bankCountry,
      psuType,
      'internal',
      null
    )
      .then((response) => {
        if (response) {
          window.location.replace(response.data);
        }
      })
      .catch((error) => {
        alert('Något gick fel, försök igen senare');
      });
  };

  const [fetchingBanks, setFetchingBanks] = useState(false);
  const resetBank = async () => {
    setFetchingBanks(true);
    await BankService.getBanks('SE')
      .then((response) => {
        setFetchingBanks(false);
        setResettingBank(true);
        setBanks(response.data);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          alert('Något gick fel försök igen om en stund');
        }
        if (error.response.status === 404) {
          alert('Kunde inte hämta några banker');
        }
      });
  };

  const filteredBanks = searchQuery
    ? banks.filter((bank) => {
        return bank.name?.toLowerCase().includes(searchQuery?.toLowerCase());
      })
    : banks;

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 bank-modal-wrapper"
    >
      <Modal.Header>
        <Modal.Title>
          {client.bankConnectionActive || client.hadBankConnection
            ? `Bankkoppling för ${client.name}`
            : `Koppla på banken för ${client.name}`}
        </Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        {(client.bankConnectionActive || client.hadBankConnection) &&
        resettingBank === false ? (
          <div className="bank-connected-view">
            {session === null ? (
              <LoadingBar active status="Hämtar..." relative />
            ) : (
              <div
                className={`bank-session-content ${
                  session.status === 'AUTHORIZED'
                    ? 'session-ok'
                    : 'session-error'
                }`}
              >
                <div className="bank-session-status">
                  <p>{session.status}</p>
                </div>
                <div className="bank-session-details">
                  <p>
                    <b>Bank: </b>
                    {session.bankName}
                  </p>
                  <p>
                    <b>Landskod: </b>
                    {session.bankCountry}
                  </p>
                  <p>
                    <b>Koppling giltig t.om: </b>
                    {Moment.utc(session.validUntil)
                      .local()
                      .format('YYYY-MM-DD HH:mm')}
                  </p>
                  {session.status !== 'AUTHORIZED' &&
                    new Date(session.validUntil) >
                      new Date(Moment.utc().local()) && (
                      <p className="session-error-text">
                        Kopplingen är ej giltig trots att det sista giltiga
                        datumet ligger i framtiden. Detta kan t.ex ske om det
                        finns störningar hos banken, om klienten har kopplat
                        banken i en annan applikation, eller då vissa banker
                        inte tillåter att samma person autentiserar bank för mer
                        än en klient.
                      </p>
                    )}
                </div>
              </div>
            )}
            {session !== null && session.status !== 'AUTHORIZED' && (
              <XpektorButton
                content="Koppla om"
                onClick={resetBank}
                loading={fetchingBanks}
              />
            )}
          </div>
        ) : (
          <div className="connect-bank-view">
            <Searchbar
              placeholder="Sök bank"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="bank-grid">
              {filteredBanks.map((data) => {
                return (
                  <div
                    className="bank-button"
                    onClick={() =>
                      redirectToBank(
                        client.id,
                        data.name,
                        data.country,
                        data.psuType
                      )
                    }
                    role="button"
                    tabIndex="0"
                  >
                    <div className="button-content-container">
                      <p>{`${data.name} (${
                        data.psuType === 0 ? 'Business' : 'Personal'
                      })`}</p>
                      <img
                        className="bank-image"
                        src={data.logo}
                        alt="Bank logo"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ConnectBankModal;
